<template>
  <div class="history-index">
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
export default {
}
</script>
<style lang="less">
.history-index {
  background-color: #fff;
}
</style>
